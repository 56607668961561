<template>
    <b-container class="liveview" fluid="" style="margin-left: 250px; width: 80%;">
        <b-container
            class="pt-3 pb-2 mb-3 border-bottom border-dark">
            <b-form-row>
                <b-col>
                    <h1 class="h2">Live View</h1>
                </b-col>

                <b-col cols="2">
                    <b-form-select
                        v-model="license_plate"
                        :options="license_plate_list"
                        @change="this.getLiveData"
                    >
                    </b-form-select>
                </b-col>
            </b-form-row>
        </b-container>

        <b-container>
            <b-row v-if="this.cardData.length > 0">
                <b-col
                    v-for="data in cardData"
                    :key="data.title"
                    class="p-1 d-flex align-items-stretch"
                    sm="12" md="5" lg="5" xl="3"
                >
                    <liveview-card
                        :cardtitle="data.title"
                        :cardtext="data.text"
                        :cardicon="data.icon"
                    ></liveview-card>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col
                    v-for="n in 8"
                    class="p-1 d-flex align-items-stretch"
                    sm="12" md="5" lg="5" xl="3"
                    :key="n"
                >
                    <b-skeleton width="100%" height="190px"/>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import LiveviewCard from "@/components/LiveviewCard.vue";

export default {
    components: {
        LiveviewCard
    },
    data() {
        return {
            license_plate_list: [],
            license_plate: "",

            cardData: []
        }
    },
    created() {
        this.$api
            .get("container/license_plates", {
                params: {},
            })
            .then((response) => {
                this.license_plate_list = response.data;
                this.license_plate = this.license_plate_list[0];
                this.getLiveData();
            })
            .catch(() => {
                return [];
            });
    },
    methods: {
        getLiveData: async function () {
            this.$api
                .get("liveview/cards", {
                    params: {
                        license_plate: this.license_plate,
                    },
                })
                .then((response) => {
                    this.cardData = [
                        {
                            title: 'Volumestrom \n' +
                                'Beladung',
                            text: response.data.current_volume_1 + ' m3/h',
                            icon: 'droplet-fill',
                        },
                        {
                            title: 'Volumestrom \n' +
                                'Entladung',
                            text: response.data.current_volume_2 + ' m3/h',
                            icon: 'droplet',
                        },
                        {
                            title: 'Leistung\n' +
                                'Beladung',
                            text: response.data.current_power_1 + ' kW',
                            icon: 'lightning-fill',
                        },
                        {
                            title: 'Leistung\n' +
                                'Entladung',
                            text: response.data.current_power_2 + ' kW',
                            icon: 'lightning',
                        },
                        {
                            title: 'Temperatur\n' +
                                'Vorlauf',
                            text: response.data.temperature_flow + '°C',
                            icon: 'thermometer-half',
                        },
                        {
                            title: 'Temperatur\n' +
                                'Rücklauf',
                            text: response.data.temperature_return_flow + '°C',
                            icon: 'thermometer',
                        },
                        {
                            title: 'Druck\n' +
                                'Salzraum',
                            text: response.data.pressure_1 + ' bar',
                            icon: 'compass-fill',
                        },
                        {
                            title: 'Druck\n' +
                                'Wasserraum',
                            text: response.data.pressure_2 + ' bar',
                            icon: 'compass',
                        },
                    ];
                })
                .catch(() => {
                    return [];
                });
        }
    }
};
</script>

<style>
</style>