<template>
    <b-card
        style="max-width: 20rem"
        class="text-center"
        text-variant="white"
        bg-variant="card"
        size="lg"
    >
        <b-row style="height: 150px; width: fit-content" align-v="center">
            <b-col cols="3">
                <b-icon
                    :icon="cardicon"
                    style="width: 50px; height: 50px;"
                />
            </b-col>
            <b-col>
                <b-row>
                    <b-col>
                        <h3>
                            {{ this.cardtitle }}
                        </h3>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h4>
                            {{ this.cardtext }}
                        </h4>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    props: {
        cardtitle: String,
        cardtext: String,
        cardicon: String,
    }
};
</script>

<style>
</style>
